@use 'sass:map';
@use '@angular/material' as mat;
@use '../../utils/typographies' as typo;
@use '../utils/mat-palettes' as pal;

$grey-palette: mat.define-palette(pal.$grey-palette);
$success-palette: mat.define-palette(pal.$success-palette);
$danger-palette: mat.define-palette(pal.$danger-palette);
$warning-palette: mat.define-palette(pal.$warning-palette);

@mixin theme() {
  .search-field {
    .mat-mdc-form-field-flex {
      background-color: white;
    }
  }

  .country-field {
    width: 6rem !important;
  }

  .phone-field {
    .mat-mdc-form-field-flex {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .mat-select-field {
    @include typo.body-2-typo(typo.$medium-weight);

    background: white;
    border: 1px solid mat.get-color-from-palette($grey-palette, 200);
    border-radius: 0.375rem;
    width: 100%;

    &:hover {
      box-shadow:
        0 0 3px rgb(13 19 27 / 16%),
        0 0.5rem 0.875rem rgb(13 19 27 / 6%) !important;
    }

    &.mat-select-panel-opened {
      border: 1px solid mat.get-color-from-palette($grey-palette, 100);
      border-bottom: none;
      border-radius: 0.375rem 0.375rem 0 0;
      box-shadow:
        0 0 3px rgb(13 19 27 / 16%),
        0 0.5rem 0.875rem rgb(13 19 27 / 6%) !important;
    }

    .mat-form-field-infix {
      border: none;
      padding: 0.75rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    mat-label {
      display: flex;
      justify-content: space-between;
    }

    .mat-pseudo-checkbox-minimal {
      margin-left: 0;
    }

    .mat-form-field-wrapper {
      padding: 0;
      margin: 0;
      border: none;

      .mat-form-field-flex {
        padding: 0.25rem 0.5rem;
      }
    }
  }
}
