@use 'sass:map';
@use '@angular/material' as mat;
@use '../../utils/typographies' as typo;
@use '../utils/mat-palettes' as pal;

$primary-palette: mat.define-palette(pal.$primary-palette);
$grey-palette: mat.define-palette(pal.$grey-palette);

@mixin theme() {
  .action-menu {
    &.mat-mdc-menu-panel {
      min-height: 0;
      padding: 0.25rem;
      background-color: mat.get-color-from-palette($grey-palette, 10);

      .mat-mdc-menu-content {
        padding: 0;
      }

      .mat-mdc-menu-item {
        @include typo.button-2-typo;
        height: inherit;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 0.5rem 0.5rem 0.5rem 1rem;
        background-color: white;
        border: none;
        align-items: center;
        cursor: pointer;
        border-radius: 0.3rem;
        gap: 0.75rem;
        color: mat.get-color-from-palette($grey-palette, 800);

        &:hover {
          background-color: mat.get-color-from-palette($primary-palette, 200);
        }

        &:disabled {
          color: mat.get-color-from-palette($grey-palette, 400);
          cursor: unset;
        }

        i {
          font-size: 1.5rem;
        }

        em {
          font-size: 1.25rem;
        }
      }
    }
  }
}
