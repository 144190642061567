@use '@angular/material' as mat;
@use '../utils/mat-palettes' as pal;
@use '../../utils/typographies' as typo;

$grey-palette: mat.define-palette(pal.$grey-palette);

@mixin theme() {
  mat-tooltip-component {
    .mdc-tooltip__surface {
      color: white;
      line-height: 1rem;
      padding: 0.5rem 1rem;
      max-width: 19.5rem;
      gap: 0.5rem;
      background-color: #{mat.get-color-from-palette($grey-palette, 900)}CC;
      @include typo.tooltip-typo();
    }
  }
}
