@import 'variables';

@mixin page-title-typo($font-weight: $semibold-weight) {
  font-family: $title-font-family-typo;
  font-style: normal;
  font-size: 2.25rem;
  line-height: 2.75rem; /* identical to box height, or 122% */
  letter-spacing: 0.2px;
  font-weight: $font-weight;
}

@mixin title-2-typo($font-weight: $semibold-weight) {
  font-family: $title-font-family-typo;
  font-style: normal;
  font-size: 1.75rem;
  line-height: 2.5rem; /* identical to box height, or 143% */
  letter-spacing: 0.1px;
  font-weight: $font-weight;
}

@mixin title-3-typo($font-weight: $semibold-weight) {
  font-family: $title-font-family-typo;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 2rem; /* identical to box height, or 133% */
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin title-4-typo($font-weight: $semibold-weight) {
  font-family: $title-font-family-typo;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 1.5rem; /* identical to box height, or 280% */
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin title-5-typo($font-weight: $semibold-weight) {
  font-family: $title-font-family-typo;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.75rem; /* identical to box height, or 280% */
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin heading-typo($font-weight: $semibold-weight) {
  font-family: $main-font-family-typo;
  font-style: normal;
  font-size: 0.88rem;
  line-height: 1.5rem; /* identical to box height, or 133% */
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin body-1-typo($font-weight: $semibold-weight) {
  font-family: $main-font-family-typo;
  font-style: normal;
  font-size: 1rem;
  line-height: 140%; /* identical to box height, or 1.38rem */
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin body-2-typo($font-weight: $semibold-weight) {
  font-family: $main-font-family-typo;
  font-style: normal;
  font-size: 0.88rem;
  line-height: 140%; /* identical to box height, or 1.25rem  */
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin caption-typo($font-weight: $semibold-weight) {
  font-family: $main-font-family-typo;
  font-style: normal;
  font-size: 0.88rem;
  line-height: 1.5rem; /* identical to box height, or 171%  */
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin subcaption-typo($font-weight: $semibold-weight) {
  font-family: $main-font-family-typo;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 1rem; /* identical to box height, or 133%  */
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin button-1-typo($font-weight: $semibold-weight) {
  font-family: $main-font-family-typo;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.25rem; /* identical to box height, or 125%  */
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin button-2-typo($font-weight: $semibold-weight) {
  font-family: $main-font-family-typo;
  font-style: normal;
  font-size: 0.9rem;
  line-height: 1.25rem; /* identical to box height, or 143%  */
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin button-3-typo($font-weight: $semibold-weight) {
  font-family: $main-font-family-typo;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 1rem; /* identical to box height, or 133%  */
  letter-spacing: 0;
  font-weight: $font-weight;
}

@mixin tooltip-typo($font-weight: $regular-weight) {
  font-family: $main-font-family-typo;
  font-style: normal;
  font-weight: $font-weight;
  font-size: 0.925rem;
  line-height: 160%;
}
