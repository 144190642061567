@use 'sass:map';
@use '@angular/material' as mat;
@use '../../utils/typographies' as typo;
@use '../utils/mat-palettes' as pal;
@use '../../utils/variables' as var;

$grey-palette: mat.define-palette(pal.$grey-palette);

@mixin theme() {
  mat-button-toggle-group {
    &.contracts-button {
      .mat-button-toggle {
        background-color: mat.get-color-from-palette($grey-palette, 10);
        color: mat.get-color-from-palette($grey-palette, 800);
        button {
          padding: 0.25rem 0.5rem;
        }
      }

      .mat-button-toggle-checked {
        background-color: mat.get-color-from-palette($grey-palette, 800);
        font-weight: bold;
        color: mat.get-color-from-palette($grey-palette, 10);
      }
    }
  }
}
