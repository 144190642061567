//----------------------------- COLORS ----------------------------

//primary-colors

$primary-color-blue: #001f3b;
$primary-color-grey: #f9f9f9;
$primary-color-green: #72d09a;

$primary-color-0: #effaf3;
$primary-color-25: #e3f7e9;
$primary-color-50: #d7f4e0;
$primary-color-100: #b2e8c5;
$primary-color-200: #29a063;
$primary-color-300: #156740;
$primary-color-400: #11432d;

$primary-color-blue-0: #eef8ff;
$primary-color-blue-50: #dcf0ff;
$primary-color-blue-100: #b2e3ff;
$primary-color-blue-200: #007bdf;
$primary-color-blue-300: #0062b4;
$primary-color-blue-400: #00447a;

//secondary-colors

$secondary-color-salmon: #f97e73;
$secondary-color-salmon-medium: #fececa;
$secondary-color-salmmon-light: #fee4e2;
$secondary-color-lin: #fffcf1;
$secondary-color-blue-light: #d8eaf9;
$secondary-color-blue-sky: #a7cfff;

//success-colors
$success-color-100: #a7cfff;
$success-color-300: #5eb4f2;
$success-color-500: #5eb4f2;
$success-color-700: #b2e8c5;

//warning-colors
$warning-color-100: #fce5d0;
$warning-color-200: #fffcf5;
$warning-color-400: #f79009;
$warning-color-500: #ee7d15;

//danger-colors
$danger-color-100: #f2b0b0;
$danger-color-500: #ee2215;
$danger-color-700: #a31919ff;

//info-colors
$info-color-100: #d3dff9;
$info-color-300: #7c9eec;
$info-color-500: #255ddf;
$info-color-700: #163886;

//grey-colors
$grey-color-50: #f8f9fa;
$grey-color-100: #f1f2f5;
$grey-color-200: #e9ebef;
$grey-color-400: #ced2da;
$grey-color-500: #adb2bd;
$grey-color-600: #868b96;
$grey-color-700: #585d66;
$grey-color-800: #1d212a;
$grey-color-900: #0d111b;

$colors: (
  danger500: $secondary-color-salmon,
  warning500: $warning-color-500,
  info500: $info-color-500,
  info100: $primary-color-blue-0,
  warning100: $warning-color-100,
  danger100: #f2b0b0,
  success500: $primary-color-green,
  greyBlack: $grey-color-900,
);

//----------------------------- TEXT ----------------------------

$font-family: 'Poppins, sans-serif';

//typography
$title-font-family-typo: 'Poppins', sans-serif;
$main-font-family-typo: 'Poppins', sans-serif;
$regular-weight: 400;
$medium-weight: 500;
$semibold-weight: 600;

//Screen sizes
$handset: 599.98px;
$handset-landscape: 959.98px;
$tablet: 839.98px;
