@use 'sass:map';
@use '@angular/material' as mat;
@use '../../utils/typographies' as typo;
@use '../utils/mat-palettes' as pal;
@use '../../utils/variables' as var;

$grey-palette: mat.define-palette(pal.$grey-palette);
$primary-palette: mat.define-palette(pal.$primary-palette);

@mixin theme() {
  .mat-mdc-outlined-button {
    padding: 0.2rem 0.25rem 0.2rem 0.5rem;
    cursor: pointer;
    border: 1.5px solid mat.get-color-from-palette($grey-palette, 400);

    .mdc-button__label {
      @include typo.button-2-typo;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;

      em {
        font-size: 1.4rem;
      }
    }

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    &:disabled {
      pointer-events: none;
      background-color: rgba(255, 0, 0, 0.05);
    }
  }

  .mat-mdc-raised-button,
  .mat-mdc-unelevated-button,
  .mat-mdc-icon-button {
    border-radius: 0.375rem;
    min-width: inherit;
    font-family: typo.$main-font-family-typo;
    border: 0;

    &.square {
      border-radius: 0.375rem;
    }

    &.circle {
      border-radius: 2rem;
    }

    &.bordered {
      border: 1px solid mat.get-color-from-palette($grey-palette, 200);
    }

    .mdc-button__label {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      gap: 0.5rem;
    }

    &:hover:enabled {
      cursor: pointer;
      opacity: 0.8;
    }

    &.default-btn {
      @include typo.button-1-typo(var.$semibold-weight);
      padding: 1.4rem 2rem;
      background-color: mat.get-color-from-palette($primary-palette, 600);
      color: #001f3b !important;

      &:disabled {
        background-color: whitesmoke !important;
        color: grey !important;
      }
    }

    &.bordered-btn {
      @include typo.button-2-typo(var.$semibold-weight);
      padding: 0.5rem 1rem;
      border: 1px solid mat.get-color-from-palette($grey-palette, 200);
      min-width: 0;
      display: flex;
      justify-content: space-around;
      align-items: center;

      em {
        font-size: 1rem;
      }
    }

    &.logout-btn {
      @include typo.button-2-typo(var.$regular-weight);
      padding: 0.5rem 1rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      em {
        font-size: 1.4rem;
      }
    }

    &.switch-profile-btn {
      @include typo.button-2-typo(var.$regular-weight);
      padding: 0.5rem 1rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      em {
        font-size: 1.4rem;
      }
    }

    &.menu-btn {
      @include typo.button-2-typo(var.$semibold-weight);

      em {
        font-size: 1.25rem;
      }
    }

    &.info-btn {
      @include typo.button-2-typo(var.$semibold-weight);
      padding: 0.75rem 1.5rem;
    }

    &.icon-btn {
      padding: 0.7rem;
      font-size: 1.5rem;

      &.square {
        border-radius: 0.375rem;
      }

      &.circle {
        border-radius: 2rem;
      }
    }

    &.back-btn {
      border: 1px solid mat.get-color-from-palette($grey-palette, 200);
      padding: 0.7rem 1.5rem;

      em {
        color: mat.get-color-from-palette($grey-palette, 900);
        font-size: 1.25rem;
      }
    }

    &.trash-btn {
      .mat-button-wrapper {
        height: 100%;
        width: 100%;
      }
    }

    &.smaller-gap {
      .mat-button-wrapper {
        gap: 0.6rem;
      }
    }
  }
}
