@use 'sass:map';
@use '@angular/material' as mat;
@use '../../utils/typographies' as typo;
@use '../utils/mat-palettes' as pal;

$primary-palette: mat.define-palette(pal.$primary-palette);
$grey-palette: mat.define-palette(pal.$grey-palette);
$success-palette: mat.define-palette(pal.$success-palette);
$danger-palette: mat.define-palette(pal.$danger-palette);
$warning-palette: mat.define-palette(pal.$warning-palette);

@mixin theme() {
  .text-input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    &.white-background-color {
      mat-form-field .mat-mdc-form-field-wrapper {
        background: mat.get-color-from-palette($grey-palette, 10);
      }
    }

    mat-label {
      @include typo.heading-typo;
      margin-bottom: 0.5rem;
    }

    mat-hint {
      @include typo.subcaption-typo(typo.$medium-weight);
      color: mat.get-color-from-palette($grey-palette, 700);
    }

    mat-error {
      @include typo.subcaption-typo(typo.$medium-weight);
      margin: 0.5rem 0;
      color: mat.get-color-from-palette($danger-palette, 500);
    }

    .mat-input-element {
      border: none;
      background: none;
      flex: 1;

      &:focus-visible {
        outline: none;
      }
    }
    .mat-mdc-form-field-suffix {
      flex: 0;
    }

    mat-form-field {
      color: transparent;
      width: 100%;

      &.readonly {
        .mat-mdc-text-field-wrapper {
          background: mat.get-color-from-palette($grey-palette, 200);

          .mat-mdc-form-field-flex {
            border: none !important;
            box-shadow: none !important;
          }
        }
      }

      .mat-mdc-form-field-subscript-wrapper {
        margin-bottom: -1em;
        display: none;
      }

      .mat-mdc-form-field-error-wrapper,
      .mat-mdc-form-field-hint-wrapper {
        padding: 0;
      }

      input::placeholder {
        color: mat.get-color-from-palette($grey-palette, 500);
      }

      &:focus-visible {
        outline: none;
      }

      &.disabled {
        pointer-events: none;

        .mat-mdc-text-field-wrapper {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }

      &.mat-focused .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
        border: 1px solid mat.get-color-from-palette($primary-palette, 600);
        box-shadow: 0 0 0 0.25rem mat.get-color-from-palette($primary-palette, 200);
      }

      &.mat-form-field-invalid .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
        border: 1px solid mat.get-color-from-palette($danger-palette, 500);
        box-shadow: 0 0 0 0.25rem mat.get-color-from-palette($danger-palette, 100);

        &:hover {
          border: 1px solid mat.get-color-from-palette($warning-palette, 500);
          box-shadow: 0 0 0 0.25rem mat.get-color-from-palette($warning-palette, 100);
        }
      }

      .mat-mdc-text-field-wrapper {
        @include typo.body-2-typo(typo.$regular-weight);
        display: flex;
        flex-direction: row;
        align-items: center;
        position: static;
        padding: 0;
        background: mat.get-color-from-palette($grey-palette, 50);
        border-radius: 0.375rem;

        .mat-mdc-form-field-flex {
          gap: 0.5rem;
          padding: 0.75rem 1rem;
          cursor: text;
          align-items: center;

          transition: all 0.5s ease-out;
          outline-color: mat.get-color-from-palette($grey-palette, 400);
          border: 1px solid mat.get-color-from-palette($grey-palette, 100);
          box-sizing: border-box;
          border-radius: 0.375rem;

          &:hover {
            border: 1px solid mat.get-color-from-palette($primary-palette, 700);
            box-shadow: 0 0 0 0.25rem mat.get-color-from-palette($primary-palette, 200);
          }

          .mat-mdc-form-field-infix {
            border: none;
            display: flex;
          }

          em {
            font-size: 1.5rem;
            vertical-align: middle;
          }

          .mat-mdc-form-field-icon-suffix {
            em {
              color: mat.get-color-from-palette($grey-palette, 900);
              cursor: pointer;
            }
          }

          .mat-mdc-form-field-prefix {
            em {
              color: mat.get-color-from-palette($grey-palette, 500);
              cursor: text;
            }
          }
        }
      }

      &.opened-autocomplete-option {
        .mat-mdc-text-field-wrapper {
          border-bottom: white;

          .mat-mdc-form-field-flex {
            border-radius: 0.375rem 0.375rem 0 0;
            border: 1px solid mat.get-color-from-palette($success-palette, 500);
            border-bottom: none;
            box-shadow: 0 0 0 0.25rem mat.get-color-from-palette($success-palette, 100);
          }
        }
      }
    }
  }

  .autocomplete {
    background: white;
    border: 1px solid mat.get-color-from-palette($success-palette, 500);
    border-top: none;
    box-shadow:
      3px 2px 0 1px mat.get-color-from-palette($success-palette, 100),
      -3px 2px 0 1px mat.get-color-from-palette($success-palette, 100);

    &.mat-mdc-autocomplete-panel {
      overflow: auto;
    }

    mat-option {
      padding-left: 3rem;
    }
  }
}
