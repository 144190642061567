@use 'sass:map';
@use '@angular/material' as mat;
@use '../../utils/typographies' as typo;
@use '../utils/mat-palettes' as pal;

$primary-palette: mat.define-palette(pal.$primary-palette);
$grey-palette: mat.define-palette(pal.$grey-palette);
$success-palette: mat.define-palette(pal.$success-palette);
$danger-palette: mat.define-palette(pal.$danger-palette);
$warning-palette: mat.define-palette(pal.$warning-palette);

@mixin theme() {
  .country-panel {
    min-width: 12rem !important;

    .mat-mdc-option .mdc-list-item__primary-text {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }

    .mat-mdc-option .mat-pseudo-checkbox-minimal {
      margin-left: 0;
    }

    .mdc-list-item--selected {
      font-weight: bold;

      .mdc-list-item__primary-text {
        color: mat.get-color-from-palette($primary-palette, 700) !important;
      }
    }
  }

  .mat-select-field {
    mat-select {
      @include typo.body-2-typo(typo.$medium-weight);
      padding: 0 0.5rem;

      &.panel-opened {
        .mat-mdc-select-placeholder {
          color: mat.get-color-from-palette($grey-palette, 500);
        }
      }

      .mat-mdc-select-placeholder {
        color: mat.get-color-from-palette($grey-palette, 900);
      }

      .mat-mdc-select-arrow-wrapper {
        height: auto;

        .mat-mdc-select-arrow {
          display: none;
        }
      }
    }

    em {
      color: mat.get-color-from-palette($grey-palette, 900);
      font-size: 1rem;
      margin-right: 0.25rem;
      transition: transform 250ms ease-in-out;

      &.rotated {
        transform: rotate(-180deg);
      }
    }
  }

  .mat-select-field-panel {
    position: absolute;
    top: 30px;
    left: -1px;
    border-radius: 0 0 0.375rem 0.375rem !important;
    box-shadow:
      0 3px 3px rgb(13 19 27 / 16%),
      0 0.5rem 0.875rem rgb(13 19 27 / 6%) !important;
    border: 1px solid mat.get-color-from-palette($grey-palette, 100);
    border-top: none;

    mat-option {
      @include typo.body-2-typo(typo.$medium-weight);
      margin: 0.5rem;
      border-radius: 0.375rem;

      &:hover {
        background-color: mat.get-color-from-palette($grey-palette, 50) !important;
      }

      &.mat-selected {
        background-color: mat.get-color-from-palette($grey-palette, 50) !important;

        span {
          color: mat.get-color-from-palette($grey-palette, 900);
        }
      }

      .mat-option-content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        em {
          color: mat.get-color-from-palette($primary-palette, 700);
          font-size: 1.25rem;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 0.375rem;
      height: 0.375rem;
    }

    &::-webkit-scrollbar-track {
      border-radius: 0.625rem;
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0.625rem;
      background: mat.get-color-from-palette($grey-palette, 100);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: mat.get-color-from-palette($grey-palette, 200);
    }

    &::-webkit-scrollbar-thumb:active {
      background: mat.get-color-from-palette($grey-palette, 300);
    }
  }

  .mat-select-field-panel ng-animating {
    visibility: hidden;
  }
}
