@use 'sass:map';
@use '@angular/material' as mat;
@use '../../utils/typographies' as typo;
@use '../utils/mat-palettes' as pal;
@use '../../utils/variables' as var;

$grey-palette: mat.define-palette(pal.$grey-palette);
$success-palette: mat.define-palette(pal.$success-palette);
$info-palette: mat.define-palette(pal.$info-palette);
$danger-palette: mat.define-palette(pal.$danger-palette);
$warning-palette: mat.define-palette(pal.$warning-palette);

@mixin column-config(
  $text-align: center,
  $font-weight: var.$regular-weight,
  $color: mat.get-color-from-palette($grey-palette, 1000),
  $background-color: transparent
) {
  @include typo.caption-typo($font-weight);
  color: $color;
  text-align: $text-align;
  background-color: $background-color;
}

@mixin theme() {
  .default-table {
    width: 100%;
    border-spacing: 0 0.25rem;

    .mat-mdc-header-row {
      @include typo.heading-typo(var.$semibold-weight);
      color: mat.get-color-from-palette($grey-palette, 800);
      height: 3rem;
      border-radius: 0.25rem;
      background-color: mat.get-color-from-palette($grey-palette, 10);
      .mat-sort-header-container {
        display: flex;
        justify-content: center;
      }
    }

    .mat-mdc-row {
      td.mat-mdc-cell {
        border-bottom: 1px solid transparent;
        border-top: 1px solid transparent;
      }

      td.mat-mdc-cell:first-child {
        margin-left: 1rem;
        border-left: 1px solid transparent;
      }

      td.mat-mdc-cell:last-child {
        margin-right: 1rem;
        border-right: 1px solid transparent;
      }
      &.row-hover-enabled {
        &.checked {
          background-color: mat.get-color-from-palette($success-palette, 100);
        }
        &:hover {
          box-shadow:
            0 0 2px rgba(13, 19, 27, 0.2),
            0 2px 0.625rem rgba(13, 19, 27, 0.1);
          cursor: pointer;
          background-color: mat.get-color-from-palette($grey-palette, 10);
          border: 1.5px solid mat.get-color-from-palette($grey-palette, 100);
          border-radius: 0.5rem;
        }
      }

      &.row-disabled {
        pointer-events: none;
        cursor: default;
      }

      &.row-border-visibled {
        td.mat-mdc-cell {
          border-bottom: 1px solid mat.get-color-from-palette($grey-palette, 300);
          border-top: 1px solid mat.get-color-from-palette($grey-palette, 300);
        }

        td.mat-mdc-cell:first-child {
          margin-left: 1rem;
          border-left: 1px solid mat.get-color-from-palette($grey-palette, 300);
        }

        td.mat-mdc-cell:last-child {
          margin-right: 1rem;
          border-right: 1px solid mat.get-color-from-palette($grey-palette, 300);
        }

        td.no-data {
          border: 1px solid mat.get-color-from-palette($grey-palette, 300);
          border-radius: 0.5rem;
        }
      }
      &.checked {
        background-color: var.$primary-color-50;

        td.mat-mdc-cell {
          border-bottom: 1px solid var.$primary-color-200;
          border-top: 1px solid var.$primary-color-200;
        }

        td.mat-mdc-cell:first-child {
          margin-left: 1rem;
          border-left: 1px solid var.$primary-color-200;
        }

        td.mat-mdc-cell:last-child {
          margin-right: 1rem;
          border-right: 1px solid var.$primary-color-200;
        }
      }
    }
    .mat-mdc-footer-cell {
      font-weight: bold;
      color: mat.get-color-from-palette($grey-palette, 800);
      font-size: 0.9rem;
      background-color: white;
      text-align: center;
      border: none;
      padding: 0 1rem;
      &.right {
        @include column-config(right, var.$semibold-weight, mat.get-color-from-palette($grey-palette, 1000), white);
      }

      &.center {
        @include column-config(center, var.$semibold-weight, mat.get-color-from-palette($grey-palette, 1000), white);
      }

      &.left {
        @include column-config(left, var.$semibold-weight, mat.get-color-from-palette($grey-palette, 1000), white);
      }
    }

    .mat-mdc-header-cell {
      font-weight: bold;
      color: mat.get-color-from-palette($grey-palette, 800);
      font-size: 0.9rem;
      background-color: white;
      text-align: center;
      border: none;
      padding: 0 1rem;
      z-index: 0 !important;

      &.right {
        @include column-config(right, var.$semibold-weight, mat.get-color-from-palette($grey-palette, 1000), white);
      }

      &.center {
        @include column-config(center, var.$semibold-weight, mat.get-color-from-palette($grey-palette, 1000), white);
      }

      &.left {
        @include column-config(left, var.$semibold-weight, mat.get-color-from-palette($grey-palette, 1000), white);
      }
    }

    .default-cell {
      border: none;
      padding: 1rem;

      &.large {
        padding: 1.5rem 1rem;
      }

      div {
        display: flex;
        align-items: center;

        em {
          padding-right: 0.6rem;
          font-size: 1.8rem;
          color: var.$grey-color-600;
          align-self: center;
        }
      }

      &.no-data {
        @include column-config(left, var.$regular-weight);
        font-style: italic;
      }

      &:first-child {
        border-radius: 0.5rem 0 0 0.5rem;
      }

      &:last-child {
        border-radius: 0 0.5rem 0.5rem 0;
      }

      &.date-column-type {
        @include column-config(center, var.$regular-weight, mat.get-color-from-palette($grey-palette, 700));
      }

      &.address-column-type {
        @include column-config(center, var.$regular-weight, mat.get-color-from-palette($grey-palette, 700));
      }

      &.state-column-type {
        &.state-active {
          @include column-config(center, var.$medium-weight, mat.get-color-from-palette($success-palette, 600));
        }

        &.state-signed {
          @include column-config(center, var.$medium-weight, mat.get-color-from-palette($info-palette, 500));
        }

        &.state-being-canceled {
          @include column-config(center, var.$medium-weight, mat.get-color-from-palette($warning-palette, 500));
        }

        &.state-cancel {
          @include column-config(center, var.$medium-weight, mat.get-color-from-palette($danger-palette, 400));
        }
      }

      &.default-column-type {
        &-semibold {
          &-right {
            @include column-config(right, var.$semibold-weight);
          }

          &-center {
            @include column-config(center, var.$semibold-weight);
          }

          &-left {
            @include column-config(left, var.$semibold-weight);
          }
        }

        &-regular {
          &-right {
            @include column-config(right, var.$regular-weight);
          }

          &-center {
            @include column-config(center, var.$regular-weight);
          }

          &-left {
            @include column-config(left, var.$regular-weight);
          }
        }
      }
    }
  }
}
