@use 'sass:map';
@use '@angular/material' as mat;
@use '../../utils/typographies' as typo;
@use '../utils/mat-palettes' as pal;

$primary-palette: mat.define-palette(pal.$primary-palette);
$success-palette: mat.define-palette(pal.$success-palette);
$info-palette: mat.define-palette(pal.$info-palette);
$warning-palette: mat.define-palette(pal.$warning-palette);
$danger-palette: mat.define-palette(pal.$danger-palette);

@mixin snackbar-style($palette) {
  @include typo.caption-typo(typo.$medium-weight);
  background-color: mat.get-color-from-palette($palette, 100);
  color: mat.get-color-from-palette($palette, 900);
  max-width: inherit;
  max-height: inherit;
  display: flex;
  flex-direction: row;
  line-height: normal;
  padding: 0;
  border-radius: 0.5rem;
  min-width: 0;
  justify-content: space-between;
  gap: 1.5rem;

  ng-component {
    display: flex;
  }

  span {
    vertical-align: middle;
    text-align: center;
    margin: auto;

    .span-text-left {
      text-align: left;
    }

    a {
      text-decoration: underline;
      font-weight: bold;
    }
  }

  em {
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    color: mat.get-color-from-palette($palette, 500);
    vertical-align: middle;
    margin: auto;

    &.closed-icon {
      font-size: 1.5rem;
      cursor: pointer;
    }
  }
}

@mixin theme() {
  .mat-mdc-snack-bar-container.snackbar {
    &-success {
      @include snackbar-style($success-palette);
      box-shadow:
        0 0 0.25rem rgba(mat.get-color-from-palette($success-palette, 300), 0.2),
        0 0 1rem rgba(mat.get-color-from-palette($success-palette, 300), 0.5);
    }

    &-danger {
      @include snackbar-style($danger-palette);
      box-shadow:
        0 0 0.25rem rgba(mat.get-color-from-palette($danger-palette, 300), 0.2),
        0 0 1rem rgba(mat.get-color-from-palette($danger-palette, 300), 0.5);
    }

    &-info {
      @include snackbar-style($info-palette);
      box-shadow:
        0 0 0.25rem rgba(mat.get-color-from-palette($info-palette, 300), 0.2),
        0 0 1rem rgba(mat.get-color-from-palette($info-palette, 300), 0.5);
    }

    &-warning {
      @include snackbar-style($warning-palette);
      box-shadow:
        0 0 0.25rem rgba(mat.get-color-from-palette($warning-palette, 300), 0.2),
        0 0 1rem rgba(mat.get-color-from-palette($warning-palette, 300), 0.5);
    }
  }
}

.snack-bar {
  background-color: rgba(mat.get-color-from-palette($primary-palette, 100), 1);
  white-space: pre-wrap;
}
