@use 'sass:map';
@use '@angular/material' as mat;
@use '../utils/mat-palettes' as pal;

$primary-palette: mat.define-palette(pal.$primary-palette);

@mixin theme() {
  mat-spinner {
    &.app-loader {
      display: block;
      position: fixed;
      z-index: 999;
      margin: auto;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      circle {
        stroke: mat.get-color-from-palette($primary-palette, 500);
      }
    }

    &.default-loader circle {
      stroke: mat.get-color-from-palette($primary-palette, 500) !important;
    }

    &.component-loader {
      display: block;
      position: fixed;
      z-index: 999;
      margin: auto;

      circle {
        stroke: mat.get-color-from-palette($primary-palette, 500);
      }
    }

    &.index-loader {
      z-index: 999;
      height: 6.25rem !important;
      width: 6.25rem !important;

      circle {
        stroke: mat.get-color-from-palette($primary-palette, 500);
      }
    }
  }
}
