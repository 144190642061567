@use 'sass:map';
@use '@angular/material' as mat;
@use '../../utils/typographies' as typo;
@use '../utils/mat-palettes' as pal;
@use '../../utils/variables' as var;

$primary-light-palette: mat.define-palette(pal.$primary-light-palette);
$grey-palette: mat.define-palette(pal.$grey-palette);
$success-palette: mat.define-palette(pal.$success-palette);
$danger-palette: mat.define-palette(pal.$danger-palette);
$warning-palette: mat.define-palette(pal.$warning-palette);

@mixin theme() {
  .saisie {
    color: white;
    &.forbidden {
      background-color: mat.get-color-from-palette($danger-palette, 300);
    }

    &.close {
      background-color: mat.get-color-from-palette($grey-palette, 500);
    }

    &.open {
      background-color: mat.get-color-from-palette($primary-light-palette, 600);
    }

    &.next {
      background-color: mat.get-color-from-palette($success-palette, 500);
    }

    &.contestable {
      background-color: mat.get-color-from-palette($warning-palette, 400);
    }

    .mat-mdc-chip-action {
      cursor: pointer;
    }
  }
}
