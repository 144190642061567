@use 'sass:map';
@use '@angular/material' as mat;
@use '../../utils/typographies' as typo;
@use '../utils/mat-palettes' as pal;
@use '../../utils/variables' as var;

$grey-palette: mat.define-palette(pal.$grey-palette);

@mixin theme() {
  mat-card {
    @include typo.button-2-typo;

    cursor: default;
    background-color: mat.get-color-from-palette($grey-palette, 10);
    border-radius: 0.75rem;
    border: 1px solid mat.get-color-from-palette($grey-palette, 200);

    .mat-card-header {
      @include typo.subcaption-typo(var.$regular-weight);

      &.header-home-side-component .mat-card-header-text {
        margin: 0;
      }
    }

    .mat-divider {
      color: mat.get-color-from-palette($grey-palette, 200);
    }

    .mat-card-title-group {
      align-items: center;
      align-content: center;

      .mat-card-title {
        display: inline;
        color: mat.get-color-from-palette($grey-palette, 900);
      }
    }

    .mat-card-title {
      font-size: 1.2rem;
      margin-bottom: 0;
    }
  }
}
