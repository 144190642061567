@use 'sass:map';
@use '@angular/material' as mat;
@use '../../utils/typographies' as typo;
@use '../utils/mat-palettes' as pal;
@use '../../utils/variables' as var;

@mixin theme() {
  .statementDate {
    button {
      padding: 0;
      height: fit-content;
      margin: 0;
    }

    em {
      position: absolute;
      transform: translate(-50%, -60%);
    }
  }
}
