@use 'sass:map';
@use '@angular/material' as mat;
@use '../../utils/typographies' as typo;
@use '../utils/mat-palettes' as pal;
@use '../../utils/variables' as var;

$grey-palette: mat.define-palette(pal.$grey-palette);

@mixin theme() {
  mat-sidenav-container {
    height: 100vh;

    .sidenav {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background: white;
      box-shadow: 0 0.75rem 1.75rem rgba(13, 19, 27, 0.08);
      border-radius: 0 0.5rem 0.5rem 0;
    }

    .mat-drawer-inner-container {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .sidenav-content {
      background-color: mat.get-color-from-palette($grey-palette, 50);
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      mat-toolbar {
        width: 100%;
      }
    }
  }
}
