@import './themes/theme';
@import './utils/variables';
@import './utils/typographies';

:root {
  @each $name, $color in $colors {
    --color-#{$name}: #{$color};
  }

  h1 {
    @include page-title-typo();
  }

  h2 {
    @include title-2-typo();

    &.section-title {
      margin-bottom: 2rem;
    }
  }

  h3 {
    @include title-3-typo();
  }

  h4 {
    @include title-4-typo();
  }

  h5 {
    @include title-5-typo();
  }

  .section-container {
    margin-bottom: 4rem;

    .section-content {
      display: flex;
      flex-direction: column;
      background-color: white;
      border-radius: 0.7rem;
      border: 1px solid $grey-color-200;
      padding: 2rem;
      gap: 1rem;
    }
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.375rem $grey-color-100;
  background-color: white;
  border-radius: 0.5rem;
}

::-webkit-scrollbar {
  width: 0.375rem;
  background-color: white;
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: $grey-color-500;
  border-radius: 0.5rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

html,
body {
  height: 100%;
}

body {
  height: 100vh;
  margin: 0;
  font-family: Poppins, 'Helvetica Neue', sans-serif;
  color: #001f3b;
}

//flex-params
.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.justify-space-around {
  justify-content: space-around;
}

.align-item-center {
  align-items: center;
}

//spacing
.m-b-10 {
  margin-bottom: 10px;
}

.m-b-5 {
  margin-bottom: 10px;
}

.m-l-5 {
  margin-left: 0.5rem;
}

//font-size
.f-16 {
  font-size: 16px;
}

.f-13 {
  font-size: 13px;
}

//line height
.line-h-25 {
  line-height: 25px;
}
